import React from "react"
import {injectIntl} from "gatsby-plugin-intl"
import Button from "../common/button";
let monthlyPrice = parseFloat(process.env.GATSBY_MONTHLY_PRICE);
let supermonthlyPrice = parseFloat(process.env.GATSBY_SUPERMONTHLY_PRICE);
let annualPrice = parseFloat(process.env.GATSBY_ANNUAL_PRICE);
const  GA_ecommerce_monthly =   {
    currency: 'EUR',
    items: [{
        item_id: 'MONTHLY',
        item_name: 'Durcal Watch MONTHLY',
        price: monthlyPrice,
        currency: 'EUR',
        quantity: 1
    }],
    value: monthlyPrice
}
const  GA_ecommerce_supermonthly =   {
    currency: 'EUR',
    items: [{
        item_id: 'SUPERMONTHLY',
        item_name: 'Durcal Watch SUPERMONTHLY',
        price: supermonthlyPrice,
        currency: 'EUR',
        quantity: 1
    }],
    value: supermonthlyPrice
}
const  GA_ecommerce_annual = {
    currency: 'EUR',
    items: [{
        item_id: 'ANNUAL',
        item_name: 'Durcal Watch ANNUAL',
        price: annualPrice,
        currency: 'EUR',
        quantity: 1
    }],
    value: annualPrice
}

const BuyButton = ({children,userId = "", purchase_method = "web",promotion_code = "", price = "", type = "primary", fpr ="",size="",location}) => {
    function getCookie(name) {
        var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"))
        if (match) return match[2]
    }

    const onPayButtonClicked = () => {
        /*window.dataLayer.push(
          {"event": "payClick","eventType":(price===process.env.GATSBY_MONTHLY?"month":"year")});*/

        window.dataLayer.push({
            "event": "payClick",
            "eventType": (price === process.env.GATSBY_MONTHLY) ? "month" :
              (price === process.env.GATSBY_ANNUAL) ? "year" :
                "supermonth"
        });

        window.dataLayer.push(function() {
            this.reset();
        })
        const ecommerceData = (price === process.env.GATSBY_ANNUAL) ? GA_ecommerce_annual :
          (price === process.env.GATSBY_SUPERMONTHLY) ? GA_ecommerce_supermonthly :
            GA_ecommerce_monthly;
        window.dataLayer.push(
          {"event": "add_to_cart","ecommerce": ecommerceData});
        window.dataLayer.push(
          {"event": "begin_checkout","ecommerce":ecommerceData});
        onSubmitPay();
    }

    const onSubmitPay = async () => {
        //window.dataLayer.push({ "event": "payClick" });
        const params = new URLSearchParams(location.search);
        const campaignID = params.get("campaign");
        const data = {
            price: price || null,
            size: size || null,
            purchase_method: userId ? "app" : purchase_method ? purchase_method : "web",
            userId: userId || null,
            promotion_code: promotion_code || fpr || null,
            shipping: true,
            campaignId: campaignID
        }

        const config = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "firstpromoter": getCookie("_fprom_tid"),
            },
            body: JSON.stringify(data),
        }

        const results = await fetch(
            `${process.env.GATSBY_API_URL}/web/stripe/checkoutSession`,
            config,
        )
        const urlToRedirect = await results.json()

        if (urlToRedirect.url) {
            window.location.assign(urlToRedirect.url)
        }
    }

    return (
        <Button id="gta_click_pay"
                onClick={onPayButtonClicked}
                btnType={type}
                rounded
                fullwidth={true}
        >
            {children}
        </Button>
    )
}

export default injectIntl(BuyButton)
