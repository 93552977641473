//import React, {createRef, useCallback, useEffect, useRef, useState} from "react"
import React, {useCallback, useEffect, useState} from "react"
import {injectIntl} from "react-intl"
import SEO from "../components/common/layout/seo"
import LayoutWatches from "../components/common/layout/LayoutWatches"
import Loadable from "@loadable/component"
import Header from "../components/homeV3/Header";
import ProsegurConnected from "../components/homeV3/ProsegurConnected";
import WatchFeatures from "../components/homeV3/WatchFeatures";
//import LoveReplacement from "../components/homeV3/LoveReplacement";
import Watch from "../components/homeV3/Watch";
//import OneYearIncluded from "../components/homeV3/OneYearIncluded";
import FAQSCard from "../components/commonV3/faqs/FAQSSection";
import Press from "../components/commonV3/Press";
import HowItWorks from "../components/homeV3/HowItWorks";
import VideoElement from "../components/homeV3/videoElement";
import Stories from "../components/homeV3/Stories";
import AllSubscription from "../components/homeV3/AllSubscription";
import AppSection from "../components/homeV3/AppSection";
import Reviews from "../components/homeV3/Reviews";
import Helmet from "react-helmet"

const BuyFixedBottom = Loadable(() => import("../components/homeV3/BuyFixedBottom"))
const VideoHowItWorks = "https://static.durcal.com/spots/Reloj-durcal-teleasistencia-salva-vidas.mp4";
//const VideoPedro = "https://static.durcal.com/spots/Durcal-reloj-camino-gps-TV.mp4";
//const VideoRosa = "https://static.durcal.com/spots/Durcal-reloj-caida-teleasistencia-TV.mp4";
//const VideoManuel = "https://static.durcal.com/spots/Durcal-reloj-respiracion-boton-de-ayuda-TV.mp4";
const VideoMariam = "https://static.durcal.com/spots/La_Realidad_Contada_Cap.6_Mariam_Felipe.mp4";
const VideoDolores = "https://static.durcal.com/spots/La_Realidad_Contada_Cap3_Dolores.mp4"
const VideoLourdes = "https://static.durcal.com/spots/La_Realidad_Contada_Cap4_Lourdes_Mabel.mp4"

const IndexPage = ({ location, intl }) => {
  const SEOTitle = intl.formatMessage({ id: "SEO_title_home" })
  const SEODescription = intl.formatMessage({ id: "SEO_description_home" })
  const [isVideoVisible, setIsVideoVisible] = useState(false)
  const [videoToShow, setVideoToShow] = useState(false)
  const handleVideoClick = (video,visible) => {
      switch (video) {
        case "how": {setVideoToShow(VideoHowItWorks);break;}
        case "Dolores": {setVideoToShow(VideoDolores);break;}
        case "Lourdes": {setVideoToShow(VideoLourdes);break;}
        case "Mariam": {setVideoToShow(VideoMariam);break;}
      }

      setIsVideoVisible(visible);
  }

  const handleScroll = useCallback(
      e => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
          window.dataLayer.push({'event': 'homepageScroll'})
        }
      }, []
  );
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  useEffect(() => {
    function handleMessage(event) {
      window.dataLayer.push({"event":event.data});
    }
    window.addEventListener('message', handleMessage, false);
  },[]);
  return (
      <>
        <Helmet>
          <link rel='canonical' href='https://www.durcal.com/es/'/>
        </Helmet>
      <LayoutWatches {...{ location }}>
      {(isVideoVisible && <VideoElement
        showVideo={handleVideoClick}
        videosURL={videoToShow}
      />)}
      <SEO title={SEOTitle} description={SEODescription} />
      <Header {...{ location, title: intl.formatMessage({id: "homeV3HeaderTitle"}), description: intl.formatMessage({id: "homeV3HeaderDescription"}) }}/>
      <ProsegurConnected/>
      <HowItWorks showVideo={handleVideoClick}/>
      <WatchFeatures/>
      <AppSection/>
      <Stories showVideo={handleVideoClick}/>
      <AllSubscription/>
      <Watch {...{ location }}/>
      <Reviews/>
      <FAQSCard/>
      <Press/>
      {(!isVideoVisible && <BuyFixedBottom  {...{ location }}/>)}
    </LayoutWatches>
</>
  )
}

export default injectIntl(IndexPage)
